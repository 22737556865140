import axios from "axios";
import { useAuth } from "@/components/AuthContext";

const apiClient = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL || "http://api.joybase.jp/api",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// トークンの取得関数
const getToken = () => localStorage.getItem("authToken");

// リクエスト時にトークンを追加
apiClient.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// レスポンス時にトークン期限切れやエラーを確認
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      const errorData = error.response.data;
      localStorage.removeItem("authToken");
      localStorage.removeItem("currentUser");
      window.location.href = "/login"; // ログイン画面にリダイレクト
    }

    // その他のエラーはそのままスロー
    return Promise.reject(error);
  }
);
export default apiClient;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronLeft } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";

const PointPage: React.FC = () => {
  const navigate = useNavigate();
  const [points, setPoints] = useState<number>(1000);

  const paymentMethods = ["クレジットカード", "PayPay", "LINE Pay", "楽天ペイ"];

  const chargeAmounts = [1000, 2000, 5000, 10000];

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>(
    paymentMethods[0]
  );

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-md my-16">
      <div className="mx-auto grid flex-1 auto-rows-max gap-4">
        {/* 所有ポイントカード */}
        <div className="flex items-center gap-4">
          <Button
            variant="outline"
            size="icon"
            className="h-7 w-7"
            onClick={() => navigate(-1)}
          >
            <ChevronLeft className="h-4 w-4" />
            <span className="sr-only">戻る</span>
          </Button>
          <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
            ポイントチャージ
          </h1>
        </div>
        <Card className="mb-6">
          <CardHeader>
            <CardTitle>所有ポイント</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-xl font-bold">{points} ポイント</p>
          </CardContent>
          <CardFooter>
            <Button variant="outline" disabled>
              現在のポイント
            </Button>
          </CardFooter>
        </Card>

        {/* 支払い方法セレクター */}
        <Card className="mb-6">
          <CardHeader>
            <CardTitle>支払い方法を選択</CardTitle>
          </CardHeader>
          <CardContent>
            <select
              value={selectedPaymentMethod}
              onChange={(e) => setSelectedPaymentMethod(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md"
            >
              {paymentMethods.map((method, index) => (
                <option key={index} value={method}>
                  {method}
                </option>
              ))}
            </select>
          </CardContent>
          <CardFooter>
            <Button variant="secondary">{selectedPaymentMethod}</Button>
          </CardFooter>
        </Card>

        {/* チャージ額リスト */}
        <Card>
          <CardHeader>
            <CardTitle>チャージ額を選択</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex flex-wrap gap-4">
              {chargeAmounts.map((amount, index) => (
                <Button
                  key={index}
                  variant="outline"
                  onClick={() => {}}
                >
                  {amount} 円
                </Button>
              ))}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default PointPage;

import React, { useState } from "react";

const TermsPage: React.FC = () => {
  return (
    <section className="bg-white">
      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16 flex items-center justify-center">
        <div className="max-w-xl lg:max-w-3xl">
          <h1 className="mt-6 text-2xl font-bold text-gray-900 sm:text-2xl text-center">利用規約</h1>

          <p className="mt-4 text-gray-700">
            本規約は、合同会社ミックスピーナッツ（以下「当社」）が提供する「JOYBASE」（以下「本サービス」）の利用条件を定めるものです。本サービスを利用されるすべての方（以下「ユーザー」）は、本規約に同意した上でご利用いただきます。
          </p>

          <h2 className="mt-6 text-lg font-semibold text-gray-900">第1条（適用）</h2>
          <p className="mt-2 text-gray-700">1. 本規約は、ユーザーが本サービスを利用する際の条件を定めるものです。</p>
          <p className="mt-2 text-gray-700">2. ユーザーは、本規約に同意した上で本サービスを利用するものとします。</p>

          <h2 className="mt-6 text-lg font-semibold text-gray-900">第2条（サービス内容）</h2>
          <p className="mt-2 text-gray-700">1. 本サービスは、オリパ抽選を提供するSaaSシステムです。</p>
          <p className="mt-2 text-gray-700">2. 本サービスの主な機能：</p>
          <ul className="mt-2 text-gray-700 list-disc pl-6">
            <li>クレジットカードを利用したポイントのチャージ</li>
            <li>ポイントを利用したオリパ抽選</li>
            <li>当選したカードの配送</li>
          </ul>

          <h2 className="mt-6 text-lg font-semibold text-gray-900">第3条（利用登録）</h2>
          <p className="mt-2 text-gray-700">1. ユーザーは、当社が定める手続きに従い利用登録を行う必要があります。</p>
          <p className="mt-2 text-gray-700">2. 登録内容に虚偽がある場合、当社は登録を拒否または無効とする場合があります。</p>

          <h2 className="mt-6 text-lg font-semibold text-gray-900">第4条（利用料金および支払い方法）</h2>
          <p className="mt-2 text-gray-700">1. 本サービスの利用には、クレジットカードを利用してポイントを購入する必要があります。</p>
          <p className="mt-2 text-gray-700">2. 購入したポイントはキャンセルできません（法律で求められる場合を除く）。</p>

          <h2 className="mt-6 text-lg font-semibold text-gray-900">第5条（抽選と配送）</h2>
          <p className="mt-2 text-gray-700">1. ユーザーは、ポイントを利用してオリパ抽選に参加できます。</p>
          <p className="mt-2 text-gray-700">2. 当選したカードはオリパ運営者から配送されます。</p>
          <p className="mt-2 text-gray-700">3. 配送に関する問題が発生した場合、当社は一切の責任を負いません。ただし、当社の重大な過失がある場合を除きます。</p>

          <h2 className="mt-6 text-lg font-semibold text-gray-900">第6条（禁止事項）</h2>
          <ul className="mt-2 text-gray-700 list-disc pl-6">
            <li>法令または公序良俗に反する行為</li>
            <li>本サービスの運営を妨害する行為</li>
            <li>他のユーザーまたは第三者への不利益な行為</li>
          </ul>

          <h2 className="mt-6 text-lg font-semibold text-gray-900">第7条（免責事項）</h2>
          <p className="mt-2 text-gray-700">1. 天災やシステム障害等の不可抗力による損害について、当社は責任を負いません。</p>
          <p className="mt-2 text-gray-700">2. ユーザー間のトラブルについて、当社は一切の関与を行いません。</p>

          <h2 className="mt-6 text-lg font-semibold text-gray-900">第8条（個人情報の取り扱い）</h2>
          <p className="mt-2 text-gray-700">当社は、プライバシーポリシーに従いユーザーの個人情報を適切に取り扱います。</p>

          <h2 className="mt-6 text-lg font-semibold text-gray-900">第9条（規約の変更）</h2>
          <p className="mt-2 text-gray-700">1. 当社は、本規約を随時変更することができます。</p>
          <p className="mt-2 text-gray-700">2. 変更後の規約は、本サービス上で公開された時点で効力を持つものとします。</p>

          <h2 className="mt-6 text-lg font-semibold text-gray-900">第10条（準拠法および管轄裁判所）</h2>
          <p className="mt-2 text-gray-700">1. 本規約は日本法を準拠法とします。</p>
          <p className="mt-2 text-gray-700">2. 紛争が生じた場合、横浜地方裁判所を第一審の専属的管轄裁判所とします。</p>
        </div>
      </div>
    </section>
  );
}
export default TermsPage;

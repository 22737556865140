import { useState } from "react";
import { useAuth } from "@/components/AuthContext";
import { Link } from "react-router-dom";
import { Coins, SquareArrowOutUpRight } from "lucide-react"
import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { toast } from "sonner"

export default function Header() {
  const { currentUser, logout } = useAuth();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const session = false;

  const handleLogout = () => {
    logout();
    toast.success("ログアウトしました");
  }

  return (
    <header className="mx-auto md:px-4 fixed top-0 left-0 w-full z-50 bg-white shadow-md">
      <div className="flex h-16 items-center gap-8 px-4">
        <Link className="flex items-center gap-2" to="/">
          <div className="flex items-center gap-2">
            <span className="sr-only">Home</span>
            <svg width="28" height="28" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
              <circle cx="100" cy="100" r="95" fill="#f2f2f2" stroke="#333" strokeWidth="5" />
              <rect x="60" y="40" width="80" height="120" rx="10" ry="10" fill="#ffffff" stroke="#333" strokeWidth="5" />
              <rect x="40" y="60" width="80" height="120" rx="10" ry="10" fill="#e2e2e2" stroke="#333" strokeWidth="5" />
              <circle cx="100" cy="100" r="20" fill="#ff6f61" />
              <circle cx="100" cy="90" r="10" fill="#ff6f61" />
              <circle cx="100" cy="110" r="10" fill="#ff6f61" />
              <text x="70" y="150" fontFamily="Arial" fontSize="30" fill="#333">A</text>
              <text x="120" y="150" fontFamily="Arial" fontSize="30" fill="#333">♠</text>
            </svg>
            <h1 className="text-lg font-semibold">JOYBASE</h1>
          </div>
        </Link>

        <div className="flex flex-1 items-center justify-end">
          <div className="flex items-center gap-4">
            {currentUser ? (
              <div>
                <div className="flex items-center gap-2">
                  <Link to="/point" className="block rounded-md bg-yellow-400 px-3 py-2 text-xs font-medium transition hover:bg-yellow-500 flex items-center gap-1">
                    <Coins size={16} className="mr-1" />
                    100pt
                  </Link>
                  <Link to="#" onClick={toggleDrawer}>
                    <img
                      alt=""
                      src="/placeholder.svg"
                      className="size-10 rounded-full object-cover"
                    />
                  </Link>
                </div>

                <div
                  className={`fixed inset-0 z-20 bg-black bg-opacity-50 transition-opacity duration-300 ${
                    isDrawerOpen ? "opacity-100" : "opacity-0 pointer-events-none"
                  }`}
                  onClick={toggleDrawer}
                ></div>

                {/* Drawer */}
                <div className={`fixed inset-y-0 right-0 w-72 bg-white z-50 transform transition-transform duration-300 ${isDrawerOpen ? "translate-x-0" : "translate-x-full"}`}>
                  <div className="flex justify-between items-center border-b border-gray-200 mb-4">
                    <h2 className="text-lg font-semibold p-4">マイページ</h2>
                    <button onClick={toggleDrawer}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>

                  <div className="flex flex-col gap-4 p-4">
                    <Card className="flex-none">
                      <CardHeader>
                        <CardTitle>所有ポイント</CardTitle>
                      </CardHeader>
                      <CardContent className="grid gap-4">
                        <CardDescription>100pt</CardDescription>
                      </CardContent>
                      <CardFooter>
                        <Button className="w-full" asChild>
                          <Link to="/point">オリパポイントを購入</Link>
                        </Button>
                      </CardFooter>
                    </Card>
                    <nav className="flex-1">
                      <ul>
                        <li>
                          <Link to="/home-address" className="block py-2">お届け先の追加・変更</Link>
                        </li>
                        <li>
                          <Link to="/orders" className="block py-2">カード獲得履歴</Link>
                        </li>
                        <li>
                          <Link to="/terms" className="block py-2">利用規約</Link>
                        </li>
                        <li>
                          <Link to="/trade-law" className="block py-2">特定商取引法に基づく表記</Link>
                        </li>
                        <li>
                          <button onClick={handleLogout} className="block py-2">ログアウト</button>
                        </li>
                      </ul>
                    </nav>
                    {currentUser?.is_owner && (
                      <Button className="w-full" asChild>
                        <Link to="/dashboard" target="_blank">店舗向けダッシュボード <SquareArrowOutUpRight size={16} className="ml-1" /></Link>
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex gap-2 md:gap-4">
                <Link
                  className="block rounded-md px-3 py-2 text-xs font-medium border border-yellow-400 transition hover:bg-yellow-500"
                  to="/login"
                >
                  ログイン
                </Link>
                <Link
                  className="block rounded-md bg-yellow-400 px-3 py-2 text-xs font-medium transition hover:bg-yellow-500"
                  to="/signup"
                >
                  会員登録
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}

import React from "react";

const PrivacyPage: React.FC = () => {
  return (
    <section className="bg-white">
      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16 flex items-center justify-center">
        <div className="max-w-xl lg:max-w-3xl">
          <h1 className="mt-6 text-2xl font-bold text-gray-900 sm:text-2xl text-center">プライバシーポリシー</h1>

          <p className="mt-4 text-gray-700">
            本プライバシーポリシー（以下「本ポリシー」）は、合同会社ミックスピーナッツ（以下「当社」）が提供するサービス「JOYBASE」
            （以下「本サービス」）において、ユーザーの個人情報の取り扱いに関する方針を定めるものです。
          </p>

          <h2 className="mt-6 text-lg font-semibold text-gray-900">1. 個人情報の定義</h2>
          <p className="mt-2 text-gray-700">
            個人情報とは、氏名、住所、電話番号、メールアドレスなど、特定の個人を識別できる情報を指します。また、他の情報と容易に照合することができ、それによって特定の個人を識別できる情報も含みます。
          </p>

          <h2 className="mt-6 text-lg font-semibold text-gray-900">2. 個人情報の取得方法</h2>
          <p className="mt-2 text-gray-700">
            当社は、ユーザーが本サービスを利用する際に、以下の方法で個人情報を取得します：
          </p>
          <ul className="mt-2 text-gray-700 list-disc pl-6">
            <li>ユーザーが本サービスに登録する際に提供された情報</li>
            <li>お問い合わせフォームやメールを通じて提供された情報</li>
            <li>利用状況の調査やアンケートへの回答を通じて取得された情報</li>
          </ul>

          <h2 className="mt-6 text-lg font-semibold text-gray-900">3. 個人情報の利用目的</h2>
          <p className="mt-2 text-gray-700">
            当社は、取得した個人情報を以下の目的で利用します：
          </p>
          <ul className="mt-2 text-gray-700 list-disc pl-6">
            <li>本サービスの提供、運営、改善</li>
            <li>本人確認、アカウント認証</li>
            <li>お問い合わせ対応</li>
            <li>重要な通知や連絡の送信</li>
            <li>統計データの作成および分析</li>
          </ul>

          <h2 className="mt-6 text-lg font-semibold text-gray-900">4. 個人情報の第三者提供</h2>
          <p className="mt-2 text-gray-700">
            当社は、以下の場合を除き、個人情報を第三者に提供することはありません：
          </p>
          <ul className="mt-2 text-gray-700 list-disc pl-6">
            <li>ユーザーの同意がある場合</li>
            <li>法令に基づく場合</li>
            <li>生命、身体または財産の保護のために必要であり、本人の同意を得ることが困難な場合</li>
            <li>公衆衛生の向上や児童の健全な育成推進のために特に必要であり、本人の同意を得ることが困難な場合</li>
          </ul>

          <h2 className="mt-6 text-lg font-semibold text-gray-900">5. 個人情報の安全管理</h2>
          <p className="mt-2 text-gray-700">
            当社は、個人情報の漏洩、紛失、破損、改ざん、または不正アクセスを防止するために適切な安全管理措置を講じます。
          </p>

          <h2 className="mt-6 text-lg font-semibold text-gray-900">6. クッキー（Cookie）の使用</h2>
          <p className="mt-2 text-gray-700">
            当社は、本サービスの利便性向上のために、Cookieを使用する場合があります。Cookieを使用することで、ユーザーの訪問履歴や利用状況を収集します。
          </p>

          <h2 className="mt-6 text-lg font-semibold text-gray-900">7. プライバシーポリシーの変更</h2>
          <p className="mt-2 text-gray-700">
            当社は、本ポリシーの内容を予告なく変更する場合があります。変更後のプライバシーポリシーは、本サービス上に掲載された時点から適用されます。
          </p>

          <h2 className="mt-6 text-lg font-semibold text-gray-900">8. お問い合わせ</h2>
          <p className="mt-2 text-gray-700">
            本ポリシーに関するお問い合わせは、以下の連絡先までご連絡ください：
          </p>
          <p className="mt-2 text-gray-700">合同会社ミックスピーナッツ</p>
          <p className="mt-1 text-gray-700">メール：info@mixpeanuts.llc</p>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPage;

import React, { useState } from "react";

const TradeLawPage: React.FC = () => {
  return (
    <section className="bg-white">
      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16 flex items-center justify-center">
        <div className="max-w-xl lg:max-w-3xl">
          <h1 className="mt-6 text-2xl font-bold text-gray-900 sm:text-2xl text-center">特定商取引法に基づく表記</h1>

          <div className="mt-6 space-y-4">
            <div>
              <h2 className="text-lg font-semibold text-gray-900">会社名</h2>
              <p className="mt-2 text-gray-700">合同会社ミックスピーナッツ</p>
            </div>

            <div>
              <h2 className="text-lg font-semibold text-gray-900">代表者</h2>
              <p className="mt-2 text-gray-700">岩元雄太郎</p>
            </div>

            <div>
              <h2 className="text-lg font-semibold text-gray-900">所在地</h2>
              <p className="mt-2 text-gray-700">〒220-0072 神奈川県横浜市西区浅間町1丁目4番3号 ウィザードビル402 </p>
            </div>

            <div>
              <h2 className="text-lg font-semibold text-gray-900">お問い合わせ先</h2>
              <p className="mt-2 text-gray-700">メールアドレス：info@mixpeanuts.llc</p>
            </div>

            <div>
              <h2 className="text-lg font-semibold text-gray-900">お支払い方法</h2>
              <p className="mt-2 text-gray-700">クレジットカード決済</p>
            </div>

            <div>
              <h2 className="text-lg font-semibold text-gray-900">キャンセル・返金</h2>
              <p className="mt-2 text-gray-700">
                サービスの性質上、購入されたポイントの返金やキャンセルには応じられません。
              </p>
            </div>

            <div>
              <h2 className="text-lg font-semibold text-gray-900">免責事項</h2>
              <p className="mt-2 text-gray-700">
                本サービスの利用において生じた損害について、当社は一切の責任を負いません。ただし、当社の故意または重大な過失による場合はこの限りではありません。
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default TradeLawPage;
